import React, { Fragment } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import darkTheme from '@/src/theme';
import ButtonFour from '@/components/buttons/TypeFour'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import defaultImage from '../../public/images/defaultImage.png';
import Link from 'next/link'

const productCard = {
  marginTop: darkTheme.spacing(1),
  marginBottom: darkTheme.spacing(5),
  boxShadow:'none',
  borderRadius:0,
  transition:`0.4s`,
  '& .cardImg':{
    borderRadius:4,
    height:250,
    boxShadow: 'none',
    transition:`0.4s`,
  },
  '& h3':{
    color: darkTheme.palette.secondary.main,
    fontSize: 24,
    fontWeight: 500,
    textTransform:'capitalize',
    fontFamily: `'Playfair Display', serif`,
    minHeight:40,
  },
  '& h5':{
    color: darkTheme.palette.common.gray,
    fontSize: 12,
    fontWeight : 300
  },
  '& h4':{
    fontSize: 22,
    fontWeight:700,
    color: darkTheme.palette.secondary.main,
  },
  '&:hover':{
    cursor:'pointer',
    '& .cardImg':{
      transform:`scale(1.04)`,
      overflow: 'hidden',
    }
  },
  '&.MuiPaper-root':{
    background: 'transparent !important'
  }
}

export default function CardOne(props) {

  return (
    <Fragment>
      <Link href={`/product/${props.item.slug}`}>
        <Card sx={{ maxWidth: 345, ...productCard }}>
          <CardMedia
            className="cardImg"
            component="img"
            alt="product"
            height="140"
            image={props.item.img_path ? props.item.img_path : defaultImage}
          />
          <CardContent sx={{px:0}}>
            <Typography gutterBottom variant="h3">
              {props.item.title.length > 16 ? props.item.title.substring(0,16)+'...' : props.item.title
              // props.item.qty || props.item.uom ? 
              //   props.item.title +'-'+ props.item.qty+''+props.item.uom :
              //   props.item.title
              }
            </Typography>
            <Typography variant="h5" sx={{minHeight:50}}>{props.item.short_description.substr(0,100)}</Typography>
          </CardContent>
          <CardActions sx={{px:0}}>
            <Stack sx={{width:'100%'}} alignItems="end" display="flex" justifyContent="space-between" flexDirection="row">
              <Typography variant="h4">
                <><CurrencyRupeeIcon sx={{fontSize:18}} /> {props.item.price}</>
              </Typography>
              <ButtonFour link={`/product/${props.item.slug}`}/>
            </Stack>
          </CardActions>
        </Card>
      </Link>
    </Fragment>
  );
}