import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import ProductCard from '@/components/cards/productCard'
import { Container, Typography, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import SectionTitle from './SectionTitle';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, FreeMode } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/pagination";


const categoriesContainer =  {
    minHeight: 200,
    py:5,
}

const categoriesContainerAlt =  {
    minHeight: 200,
    py:5,
    background: '#FFFDF3'
}

function HomeCategories(props) {
    const [loading, setLoading] = useState(true);
    const [listProducts, setListProducts] = useState([]);
    let SkeletonCards = Array(4).fill(0);

    useEffect(()=>{
        fetchCategoryProducts(props.data.id)
    },[props.data]);

    const fetchCategoryProducts = async(categoryId)=>{
        await axios.get(`/api/categories/listProductsById?categoryId=${categoryId}`,{
            headers:{
                'x-auth-token': process.env.JWT_TOKEN
            }
        }).then((response)=>{
            setListProducts(response.data.result ? response.data.result : []);
            setLoading(false);
        })
    }
  return (
    <Fragment>
        <Typography component="div" sx={props.type == 1 ? categoriesContainer : categoriesContainerAlt}>
            <Container>
                <Typography component="div">
                    <SectionTitle title={props.data.title} text={props.data.description} link={props.data.slug} />
                    {loading ? (
                        <Typography component="div">
                            <Grid container spacing={3}>
                                {SkeletonCards.map((item) => (
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={nanoid(30)}>
                                        <Typography component="div" sx={{mb:2}}>
                                            <Skeleton 
                                                variant="rectangular" 
                                                height={250} 
                                                width={250}
                                                sx={{bgcolor: 'main.900', borderRadius:2}} 
                                            />
                                            <Skeleton 
                                                variant="h3" 
                                                height={26} 
                                                width={250}
                                                sx={{fontSize:26, bgcolor: 'main.900', mt:2}} 
                                            />
                                            <Skeleton 
                                                variant="text" 
                                                height={20} 
                                                width={250}
                                                sx={{fontSize:22, bgcolor: 'main.900', mt:1}} 
                                            />
                                            <Skeleton 
                                                variant="text" 
                                                height={20} 
                                                width={150}
                                                sx={{fontSize:22, bgcolor: 'main.900'}} 
                                            />
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Typography>
                    ):(
                        <Typography component="div" className="products-swiper">
                            <Swiper
                                controls={[Navigation, Pagination, Autoplay, FreeMode]}
                                spaceBetween={30}
                                slidesPerView={1.5}                  
                                freeMode={true}
                                autoHeight={true}
                                lazy={{ loadPrevNext: true }}
                                autoPlay={{ delay: 10000, disableOnInteraction: true }}
                                speed={1500}
                                loop={true}  
                                pagination= {true}
                                // navigation
                                breakpoints={{
                                    1023: {
                                        width: 1023,
                                        slidesPerView: 3.75,
                                    },
                                    990: {
                                        width: 990,
                                        slidesPerView: 3,
                                    },
                                    768: {
                                        width: 768,
                                        slidesPerView: 2,
                                    },
                                    425: {
                                        width: 425,
                                        slidesPerView: 1.5,
                                    },
                                }}                
                            >  
                                {listProducts.map((item)=>(
                                    <SwiperSlide key={nanoid(20)}>
                                        <ProductCard item={item} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Typography>
                    )}
                </Typography>
            </Container>
        </Typography>
    </Fragment>
  )
}

export default HomeCategories