import React, {Fragment} from 'react'
import {Button, Typography} from '@mui/material'
import darkTheme from '@/src/theme';
import {useRouter} from 'next/router'

function TypeOne(props) {

  const router = useRouter();

  const buttonContainer = {
    m:0,
    background:darkTheme.palette.buttons.main,
    border:`1px solid ${darkTheme.palette.buttons.main}`,
    color:darkTheme.palette.buttons.alt,
    textDecoration:'none',
    textAlign:'center',
    borderRadius: darkTheme.spacing(6),
    fontSize: 14,
    px:2,
    py:0.5,
    fontWeight:500,
    transition:`0.5s`,   
    display:'flex',
    justifyContent:'space-between', 
    "&:hover":{
      transition:`0.5s`,  
      color:darkTheme.palette.buttons.main,
      background:darkTheme.palette.buttons.alt,
      border:`1px solid ${darkTheme.palette.buttons.alt}`,
    },
  }

  const gotoLink = (event,link) => {
    event.preventDefault()
    router.push(link, undefined, { shallow: true })
  }

  return (
    <Fragment>
      <Typography component="div">
        <Button type="button" sx={buttonContainer} onClick={(event)=>gotoLink(event, props.link)}>
            Buy Now
        </Button>
      </Typography>
    </Fragment>
  )
}

export default TypeOne