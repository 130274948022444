import React, { Fragment } from "react";
import { Container, Stack, Typography } from "@mui/material";
import darkTheme from '@/src/theme';
import Buttontwo from '@/components/buttons/TypeTwo'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, FreeMode } from "swiper/modules";
import { nanoid } from 'nanoid';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/pagination";

const BannerHome = (props) => {  
  console.log(props.banners);
  const bannerContainer = {
    height:'90vh',
    width:'100%',
    backgroundPosition:'center !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize:'cover !important',
    pb: darkTheme.spacing(2),
    mt: darkTheme.spacing(8)
  }
  
  const bannerTitle = {
    fontWeight: 700,
    fontSize: {md:80,sm:50, xs:40},
    textTransform: 'capitalize',
    color: '#fff',
    mb: darkTheme.spacing(4),
    fontFamily: `'Playfair Display', serif`,
  };  
      
  return (
      <Fragment>
        <Swiper
          controls={[Navigation, Pagination, Autoplay, FreeMode]}
          spaceBetween={0}
          slidesPerView={1}                  
          freeMode={true}
          autoHeight={true}
          lazy={{ loadPrevNext: true }}
          autoPlay={{ delay: 10000, disableOnInteraction: true }}
          speed={1500}
          loop={true}  
          pagination= {true}              
      >  
        {props.banners&&props.banners.map((item)=>(
          <SwiperSlide key={nanoid(20)}>
            <Typography component="div" sx={{...bannerContainer, 
              background: `url(${item.img_path ? item.img_path : '/images/banner.png'})`
            }}>
              <Container maxWidth="lg">
                <Typography component="div" sx={{height:'100vh', display:'flex', alignItems:'center'}}>
                  <Stack display="flex" flexDirection="column" alignItems='flex-start' sx={{ width:'60%', minHeight: '50vh', textAlign:'left'}}>
                    {item.title && (
                    <Typography variant="h1" sx={bannerTitle}>
                      {item.title ? item.title : `Flavour's of Telugu`}
                    </Typography>
                    )}
                    {item.button_url && (
                      <Buttontwo link={item.button_url ? item.button_url : '/collections'} />
                    )}
                  </Stack>
                </Typography>
              </Container>
            </Typography>
          </SwiperSlide>
        ))}
      </Swiper>
    </Fragment>   
  )
}

export default BannerHome
