import React, { useState, useEffect, Fragment } from 'react';
import { Typography, Stack, Button } from '@mui/material'
import darkTheme from '@/src/theme';
import Skeleton from '@mui/material/Skeleton';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import { useRouter } from 'next/router';

const sectionContainer = {
    mb:3,
    '& h2':{
        fontSize: 60,
        fontWeight: 700,
        color: darkTheme.palette.primary.main,
        fontFamily: `'Playfair Display', serif`,
        textTransform: 'capitalize',
        mb:3,
        [darkTheme.breakpoints.down("md")]: {
            fontSize: 40, 
            maxWidth: 'unset',
        }
    },
    '& h5':{
        fontSize: 16,
        fontWeight: 300,
        // maxWidth: '75%',
        color: darkTheme.palette.common.gray,
        mb:5,
        [darkTheme.breakpoints.down("md")]: {
            fontSize: 12,
            maxWidth: '90%',
        }
    },
}

function SectionTitle(props) {

    const [loading, setLoading] = useState(true);
    const Router = useRouter();

    useEffect(()=>{
        setLoading(false)
    },[props.title])

    const followLink = (event,link) => {
        event.preventDefault();
        Router.push(link);
    }

  return (
    <Fragment>     
        <Typography component="div" sx={{...sectionContainer}}>
            {loading ? (
            <Stack display="flex" flexDirection="column" sx={{width:'100%'}}>
                <Skeleton variant="h2" sx={{ fontSize:50, width:300, bgcolor: 'main.900' }}/> 
                <Skeleton variant="text" sx={{ fontSize:16, width:600, mt:3, bgcolor: 'main.900' }}/>
                <Skeleton variant="text" sx={{ fontSize:16, width:600, bgcolor: 'main.900' }}/>
            </Stack>
            ):(
                <Typography component="div">
                    <Stack display="flex" justifyContent="space-between" flexDirection="row" alignItems="center">
                        <Typography variant="h2">
                            {props.title}
                        </Typography> 
                        {props.link ? (
                        <Button type="button" size="small" variant="outlined" 
                            sx={{
                                borderRadius:10,
                                '&:hover':{
                                    background: darkTheme.palette.primary.main,
                                    color: darkTheme.palette.primary.alt
                                }
                            }}
                            onClick={(event)=>followLink(event, '/collections/'+props.link)}
                        >
                            View All <ArrowRightAlt />
                        </Button>
                        ):('')}
                    </Stack>
                <Typography variant="h5">{props.text}</Typography> 
            </Typography>
            )}
        </Typography>        
    </Fragment>
  )
}

export default SectionTitle